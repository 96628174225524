import styled, { createGlobalStyle, keyframes } from 'styled-components';
//Styles
import { colors, fonts, gap, flex, boxsizing, gradient, titles } from './Mixins';
import { dataUri } from './DataUri';
import { animationSky, animationPlane } from './Keyframes';

import MenuIcon from '@material-ui/icons/Menu';


//Media Queries

import { device } from './device';

    //Styled sections

    export const GlobalStyle = createGlobalStyle`

        body {
            padding: 0; margin: 0; display: block; width: 100%; height: auto; background-color: ${colors.white}; font-family: ${fonts.two}; color: white; font-size: ${fonts.size14pt};

            #sectionThird {
                background-image: ${dataUri.one}; background-repeat: no-repeat; background-position: center top;
                background-size: 100% auto;
            }

            .mymodal {
                position: absolute;
                top: 50px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                border: 1px solid rgb(204, 204, 204);
                background: rgb(255, 255, 255);
                overflow: auto;
                border-radius: 4px;
                outline: none;
                padding: 5px;

                @media ${device.laptop} {
                    top: 70px;
                }
            }

            .Header {
                &.HeaderScrolled {
                    z-index: 100;
                    header {
                        background-color: rgba(255,255,255, 0.9);
                        z-index: 100;
                        border-bottom: solid 1px #d8d8d8;

                        nav ul li a span a {
                            color: ${colors.black};
                            &:hover {
                                color: ${colors.blueLigth};
                                text-decoration: none;
                            }
                            &.active {
                                color: ${colors.blueLigth};
                            }
                        }
                    }
                }

                .menuBlue {
                    path {
                        fill: ${colors.blueDark};
                    }
                }

                .menuBlue, .menuWhite {
                    max-height: 23px;
                    max-width: 28px;

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }

                .MuiIconButton-label {
                    ${flex.flexCross}
                    ${flex.flexcentervertical}
                    ${flex.flexjustifyhorizontal}

                    img {
                        max-width: 60%;
                    }
                }

                .makeStyles-menuButton-6, .makeStyles-menuButton-7 {
                    margin-right: 0;
                }
            }

            .divContent {
                display: block;
                margin: 0 auto;
                width: 100%;
                max-width: 1280px;
            }

            h1 {
                ${titles.video};
                max-width: 100%;
                @media ${device.laptop} {
                    font-size: ${fonts.size30pt};
                    max-width: 1000px;
                }
            }

            h2 {
                ${titles.title};
                ${flex.flexCross}
                ${flex.flexcentervertical}
                margin: 0 auto ${gap.gap20};
                &:before {
                    content:'';
                    width: calc(24.5px * 2);
                    height: calc(0.5px * 6);
                    background-color: ${colors.textbluelight};
                    margin: 0 ${gap.gap10} 0 0;
                    display: block;
                }

                @media ${device.laptop} {
                    font-size: ${fonts.size46pt};
                }
            }

            .MuiDivider-root {
                &.dividerShort {
                    width: calc(100% - 60px);
                    margin: 0 0 0 60px;
                    @media ${device.laptop} {
                        width: calc(100% - 60px);
                        margin: 0 0 0 60px;
                    }
                }
            }

            .MuiButton-label {
                span {
                    ${titles.title};
                    ${flex.flexCross}
                    ${flex.flexcentervertical}
                    margin: 0 auto;
                    font-size: ${fonts.size15pt};
                    font
                    text-transform: initial;
                    &:before {
                        content:'';
                        width: calc(24.5px * 2);
                        height: calc(0.5px * 6);
                        background-color: ${colors.textbluelight};
                        margin: 0 ${gap.gap10} 0 0;
                        display: block;
                    }
                }
            }

            .last-item {
                margin: 30px 0 0 0;
                span {
                    &:before {
                        background-color: transparent;
                    }
                }
            }

            .MuiIconButton-root {
                padding: 14px;
                margin-right: 0;
            }

            h3 {
                ${titles.subtitulos};
                @media ${device.laptop} {
                    font-size: ${fonts.size20pt};
                }
            }

            p {
                ${titles.cuerpodetexto};
                padding: 0 0 0 calc(${gap.gap10} * 6);
                text-align: left;
                @media ${device.laptop} {
                    font-size: ${fonts.size16pt};
                }
            }

            a {
                ${titles.linkdetexto};
                &:hover {
                    text-decoration: underline;
                }
            }

            * {
                text-decoration: none; transition: linear .2s all; ${boxsizing.box}; position: relative; z-index: 1;
            }

            .itemGrid {
                &-PaddingDesktop {
                    margin: 0 auto 0 0;
                    padding: 0 0 0 calc(${gap.gap10} * 3);
                    @media ${device.laptop} {
                        margin: 0 auto calc(${gap.gap10} * 3) 0;
                        padding: 0 0 0 calc(${gap.gap10} * 6);
                    }
                }
                &-PaddingThirthy {
                    margin: 0 auto calc(${gap.gap10} * 3) 0;
                    padding: 0 0 0 calc(${gap.gap10} * 3);
                }
                &-image {
                    ${flex.flexcolumns}
                    ${flex.flexbottomvertical}
                    ${flex.flexjustifyhorizontalleft}
                    img {
                        width: 80%;
                        height: auto;
                        display: block;
                        margin: ${gap.gap20} auto 0;
                    }
                }
            }

            .centerAll {
                ${flex.flexCross}
                ${flex.flexcentervertical}
                ${flex.flexjustifyhorizontalleft}

                h2 {
                    width: 100%;
                }
            }

            .containerImage {
                display: block;
                margin: 0 0 0 calc(${gap.gap10} * 6);
                padding: 0 0 12px 0;
                text-align: right;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    width: calc(100% - 14px);
                    height: calc(100% - 14px);
                    max-width: 500px;
                    border: solid 0.5px #979797;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                img {
                    width: calc(100% - 14px);
                    position: relative;
                    max-width: 500px;
                    margin: 0;
                }

                @media ${device.laptop} {
                    &:before {
                        max-width: 500px;
                    }
                    img {
                        max-width: 500px;
                    }
                }
            }



            .MuiBackdrop-root {
                width: 100%;
                max-height: 56px;
                display: none;
                @media ${device.laptop} {
                    max-height: 68px;
                }
            }

            .MuiDrawer-paper {
                width: 100vw;
                height: calc(100vh - 54px);
                position: relative;

                nav {
                    position: relative;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;

                    .makeStyles-menuButton-8 {
                        margin-right: 0;
                    }

                    @media ${device.mobileL} {
                        position: static;
                        top: 0;
                        transform: none;
                        padding: 0 0 30vmin;
                    }
                }
            }

            header {
                border-bottom: none;
                justify-content: flex-end;
                align-items: flex-end;
                .makeStyles-menuButton-3 {
                    margin: 0;
                }
                .MuiToolbar-gutters {
                    margin: 0;
                    padding: 0;
                    justify-content: space-between;
                    width: 100%;
                    min-height: 56px;

                    .caracol-logo {
                        padding: 14px;
                        width: auto;
                    }
                }
                nav {
                    display: block;
                    margin: 0 auto;
                    width: 100%;
                    ul {
                        list-style: none;
                        ${flex.flexCross}
                        margin: 0 auto;
                        padding: 0;
                        text-align: center;
                        width: 100%;
                        li {
                            margin: 0 auto;
                            padding: 0;
                            display: inline-block;
                            a {
                                text-aling: center;
                                color: ${colors.white};
                                padding: 0;
                                &.MuiButton-text {
                                    padding: 0;
                                }
                                span {
                                    color: ${colors.white};
                                    height: 69px;
                                    padding: 0 ${gap.gap10};
                                    a{
                                        font-weight: normal;
                                        height: 69px;
                                        font-family: ${fonts.one};
                                        ${flex.flexCross}
                                        ${flex.flexcentervertical}
                                        ${flex.flexjustifyhorizontal}

                                    }
                                }
                                &:hover {
                                    color: ${colors.blueLigth};
                                    background-color: rgba(0, 0, 0, 0) !important;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                .caracol-logo {
                    width: 100%;
                    max-width: 200px;
                    ${flex.flexCross}
                    ${flex.flexcentervertical}
                    ${flex.flexjustifyhorizontaleft}
                    padding: 14px 26px;
                    height: 68px;
                    span {
                        &.MuiButton-label {
                            padding: 0;
                        }
                    }

                    img {
                        max-width: 120px;
                    }

                    &-mobile {
                        height: 56px;
                    }
                }
            }

            .btnmenudesk {
                padding: 0;
                height: 69px;
                span {
                    color: ${colors.white};
                    height: 69px;
                    padding: 0 ${gap.gap10};
                    ${titles.itemmenu}
                }
            }

            .Video {
                width: 100%;
                height: 100vh;
                overflow: hidden;
                background-color: ${colors.black};
                ${flex.flexCross}
                ${flex.flexcentervertical}
                ${flex.flexjustifyhorizontal}
                padding: 0 ${gap.gap10};
                position: relative;
                h1 {
                    text-align: center;
                    position: relative;
                    z-index: 2;
                }
                video {
                    position: absolute;
                    height: 100%;
                    overflow: hidden;
                    left: 50%;
                    transform: translate(-50%, 0) scale(1.3);
                    top: 0;
                }
                &:after {
                    content : '';
                    display: block;
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.6);
                }
            }

            .btnMouse {
                display: block;
                position: absolute;
                width: 6vw;
                height: 12vw;
                background-color: transparent;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                cursor: pointer;

                img {
                    width: 100%;
                    height: auto;
                    mix-blend-mode: screen;
                }

                .visible {
                    display: block;
                }

                .invisible {
                    display: none;
                }

                @media ${device.laptop} {
                    width: 2vw;
                    height: 4vw;
                }
            }

            .divContent {
                width: 100%;
                overflow: hidden;
                ${flex.flexCross}
                padding: 0 ${gap.gap10};
                margin: 0 auto 50px;

                &-paddingThirty {
                    margin: 0 auto calc(3*${gap.gap20});
                }

                .MuiButton-root {
                    background-color: ${colors.textbluedark};
                    color: ${colors.white};
                    margin: ${gap.gap20} 0 0 0;
                    padding: 10px 20px;
                    display: block;
                    text-transform: capitalize;
                    font-family: ${fonts.two};
                    font-size: ${fonts.size20pt};
                    line-height: normal;
                    letter-spacing: normal;

                    @media ${device.laptop} {
                        display: inline;
                        margin: 0 0 0 ${gap.gap20};
                        font-size: ${fonts.size20pt};
                    }

                    &:hover {
                        background-color: ${colors.black};
                    }
                }

                &.firstSection {
                    @media ${device.laptop} {
                        h2 {
                            margin: 70px 0 0 0;
                        }
                    }
                }

            }

            .divAcordeon {

                .MuiAccordionSummary-content {
                    margin: ${gap.gap20} 0 ;

                    p.MuiTypography-body1 {
                        ${titles.itemaccordion}
                        @media ${device.laptop} {
                            font-size: ${fonts.size20pt};
                        }
                    }

                    &.Mui-expanded {
                        p.MuiTypography-body1 {
                            color: ${colors.blueLigth};
                        }
                    }

                }

                .MuiAccordionSummary-root {
                    padding: 0;
                }

                .MuiAccordionDetails-root {
                    padding: 0;
                    p.MuiTypography-root {
                        ${titles.itemaccordion}
                        font-weight: normal;
                    }
                    @media ${device.laptop} {
                        padding: 0 16px 40px;
                    }
                }

                .MuiButtonBase-root {
                    align-items: flex-start;
                    @media ${device.laptop} {
                        padding: 20px 16px !important;
                    }
                    &.Mui-expanded {
                        .MuiIconButton-root {
                            color: ${colors.blueLigth};
                        }
                        min-height: 20px;
                        @media ${device.laptop} {
                            padding: 20px 16px 0;
                        }
                    }
                    .MuiAccordionSummary-expandIcon {
                        padding: 20px 0px;
                    }
                }

                .MuiAccordion-root {
                    &.Mui-expanded {
                        &:before {
                            opacity: 1;
                        }
                    }
                }

                .MuiIconButton-root {
                    color: ${colors.black};
                    .MuiSvgIcon-root {
                        font-size: 2.5rem;
                    }
                }

                .MuiPaper {
                    &-elevation4 {
                        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
                        border-bottom: solid 1px rgba(255,255,255,0.2);
                    }

                    &-root {
                        box-shadow: none;

                        &:before {
                            width: calc(100% - 50px);
                            left: inherit;
                            opacity: 0 !important;
                        }

                        @media ${device.laptop} {
                            &:before {
                                width: calc(100% - 20px);
                            }
                        }
                    }
                }

            }

            .carouseLogos {
                display: block;
                margin: calc(${gap.gap20} * 2) auto;

                div.contentImage {
                    text-align: center;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    span {
                        color: ${colors.black};
                        display: block;
                        margin. 0 auto;
                        ${titles.yeartext}
                    }
                }

                >div >div >div {
                    &:first-child {
                        >div {
                            transition: none;
                        }
                    }
                }
            }

            .contentImageTitle {
                width: 95%;
                display: block;
                margin: 0 auto;
                height: auto;
                min-height: 326px;
                border-radius: 30px 30px 30px 0;
                box-shadow: 0 0 10px 0 #efefef;
                background-color: #ffffff;
                padding: ${gap.gap20};
                ${flex.flexcolumns}
                ${flex.flexbottomvertical}
                ${flex.flexjustifyhorizontalleft}
                position: relative;
                img {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    display: block;
                }
                h3 {
                    color: ${colors.black};
                    width: 70%;
                    min-height: 40px;
                    text-align: left;
                    padding: 0 0 ${gap.gap20};
                    margin: 0;
                    position: absolute;
                    bottom: 15px;
                    left: 5%;
                    align-items: bottom;
                    font-family: ${fonts.one};
                    font-weight: 600;
                    &:before {
                        content:'';
                        width: calc(33px * 2);
                        height: calc(0.5px * 6);
                        background-color: ${colors.textbluelight};
                        margin: 0 ${gap.gap10} 0 0;
                        display: block;
                        position: absolute;
                        bottom: 0;
                    }
                }

            }

            .contentServicesText {
                width: 100%;
                p {
                    padding: ${gap.gap20} ${gap.gap20};
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                    ${titles.cuerpodetexto}
                }
            }

            .itemsPossiblesUses {
                h3 {
                    padding-top: 0;
                    margin-top: 0;
                    strong {
                        color: ${colors.textbluelight};
                    }
                }
                p {
                    padding: 0;
                    ${titles.cuerpodetexto}
                    position: static;
                    @media ${device.laptop} {
                        font-size: ${fonts.size16pt};
                    }
                    &:before {
                        content: '';
                        width: 1px;
                        position: absolute;
                        height: 100%;
                        left: 0;
                        top: 0;
                        display: block;
                        background-color: ${colors.textbluelight};

                    }
                }
                padding: ${gap.gap20}  ${gap.gap20} ${gap.gap20} ${gap.gap30};
                position: relative;
                margin: 0 0 15px;
                height: auto;
                clear: both;
                cursor: pointer;

                @media ${device.laptop} {
                    padding: ${gap.gap20}  calc(3 * ${gap.gap20}) ${gap.gap20} ${gap.gap20};
                    height: calc(100% - calc(3 * ${gap.gap20}));
                }

                &:hover {
                    h3 {
                        strong {
                            color: ${colors.textbluelight};
                        }
                    }
                    p {
                        &:before {
                            background-color: ${colors.textbluelight};
                        }
                    }
                }
            }

            .divAcordeon {
                .makeStyles-root-1 {
                    padding: 0;
                }
                p {
                    flex-basis: 100%;
                    padding: 0 35px 0 60px;

                    &.MuiTypography-root {
                        padding: 0 35px 0 60px;
                        @media ${device.laptop} {
                            padding: 0 35px 0 60px;
                        }
                    }

                    p {
                        padding: 0;
                        flex-basis: 90%;
                    }
                }

                h4 {
                    margin: 0;
                }

                @media ${device.laptop} {
                    .makeStyles-root-1 {
                        padding: 0 0 0 calc(10px * 6);
                        p {
                            padding: 0;
                        }
                    }
                }
            }
        }
    `


    export const FeaturedLogo  = styled.div`
        background-image: url(${props => props.featuredImageLogo}) , url(${props => props.preload});
    `