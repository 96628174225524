import React, { Fragment, useState  } from 'react';
import { AppBar, makeStyles, Toolbar, Typography, Grid, Link, Button, ButtonGroup  } from '@material-ui/core';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import BackVideo from './BackVideo';


// imagenes
import LogoWhite from '../images/logo-mesa-de-trabajo-1-copia@3x.png';
import LogoBlue from '../images/logo-mesa-de-trabajo-1@3x.png';

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar
}));

const MenuDesktop = ({ConstLanguage, setConstLanguage, hasscrolled}) => {

    const[varActive, setActive] = useState('init');

    const getLanguage = ConstLanguage;

    const preventDefault = (event) => event.preventDefault();

    const classes = useStyles();

    const changueLanguage = (e) => {
        getLanguage ? setConstLanguage(false) : setConstLanguage(true);
        preventDefault(e);
    }

    // Esta funcion determina el elemento activo del menu
    const handleClick = (e) => {
        const clicked = e.target.id
        const hasclicked = e.target.classList
        if(hasclicked == 'active') {
            setActive('');
        } else {
            setActive(clicked)
        }
      }

    return (
        <Fragment>
            <AppBar position="fixed" color="transparent">
                <Grid container spacing={0}>
                <Grid item xs={12} sm={2}>
                    <AnchorLink offset='100' href='#root' className="caracol-logo">
                        {hasscrolled ?
                            <img src={ LogoBlue } alt="LOGO CARACOL TELEVISION"></img>
                        :
                            <img src={ LogoWhite } alt="LOGO CARACOL TELEVISION"></img>
                        }
                    </AnchorLink>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <nav>
                        <ul>
                            <li>
                                <Button href="#sectionOne">
                                    <AnchorLink offset='100' href='#sectionOne' className={`${varActive == 'one' ? 'active': 'inactive'}`} onClick={(e) => handleClick(e)} id="one">
                                        { getLanguage ? 'QUIENES SOMOS' : 'WHO WE ARE' }
                                    </AnchorLink>
                                </Button>
                            </li>
                            <li>
                                <Button href="#" onClick={preventDefault}>
                                    <AnchorLink offset='100' href='#sectionTwo' className={`${varActive == 'two' ? 'active': 'inactive'}`} onClick={(e) => handleClick(e)} id="two">
                                        { getLanguage ? 'SERVICIOS' : 'SERVICES' }
                                    </AnchorLink>
                                </Button>
                            </li>
                            <li>
                                <Button href="#" onClick={preventDefault}>
                                    <AnchorLink offset='100' href='#sectionThird' className={`${varActive == 'third' ? 'active': 'inactive'}`} onClick={(e) => handleClick(e)} id="third">
                                        { getLanguage ? 'POSIBLES USOS' : 'POSSIBLE USES' }
                                    </AnchorLink>
                                </Button>
                            </li>
                            <li>
                                <Button href="#" onClick={preventDefault}>
                                    <AnchorLink offset='100' href='#sectionFour' className={`${varActive == 'four' ? 'active': 'inactive'}`} onClick={(e) => handleClick(e)} id="four">
                                        { getLanguage ? 'REALICE SU SOLICITUD' : 'MAKE YOUR REQUEST' }
                                    </AnchorLink>
                                </Button>
                            </li>
                            <li>
                                <Button href="#" onClick={preventDefault} >
                                    <AnchorLink offset='100' href='#sectionFive' className={`${varActive == 'six' ? 'active': 'inactive'}`} onClick={(e) => handleClick(e)} id="six">
                                        { getLanguage ? 'PREGUNTAS Y RESPUESTAS' : 'Q&A' }
                                    </AnchorLink>
                                </Button>
                            </li>
                            <li>
                                <Button href={ getLanguage ? 'https://archivoaudiovisual.caracoltv.com/tarifas2024.xlsx' : 'https://archivoaudiovisual.caracoltv.com/tarifas2024.xlsx'} className={`${varActive == 'seven' ? 'active': 'inactive'}`} onClick={(e) => handleClick(e)} id="six">
                                    <a>
                                        { getLanguage ? 'TARIFAS' : 'RATE CARD' }
                                    </a>
                                </Button>
                            </li>
                        </ul>
                    </nav>
                </Grid>
                <Grid item xs={12} sm={1}></Grid>
                <Grid item xs={12} sm={1}>
                    <nav>
                        <ul>
                            <li>
                                <Button href="#" onClick={(e) => changueLanguage(e)}  >
                                    <AnchorLink>
                                        { getLanguage ? 'ENGLISH' : 'ESPAÑOL' }
                                    </AnchorLink>
                                </Button>
                                </li>
                        </ul>
                    </nav>
                </Grid>
            </Grid>
            </AppBar>
            <BackVideo ConstLanguage={ConstLanguage} hasscrolled={hasscrolled}></BackVideo>
            <div className={classes.offset}></div>
        </Fragment>
    );
}

export default MenuDesktop;