import React, { Fragment } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {
    List,
    ListItem,
    ListItemText,
    Button
} from '@material-ui/core'

const Listas = ({ConstLanguage, setConstLanguage, setAbrir}) => {

    const getLanguage = ConstLanguage;

    const preventDefault = (event) => event.preventDefault();

    const changueLanguage = (e) => {
        getLanguage ? setConstLanguage(false) : setConstLanguage(true);
        preventDefault(e);
    }

    const closeMenu = (e) => {
        setAbrir(false);
    }

    return (
        <Fragment>
            <List component='nav'>
                <ListItem button>
                    <Button href="#" onClick={preventDefault}>
                        <AnchorLink offset='100' href='#sectionOne' onClick={(e) => closeMenu(e)}><span>{ getLanguage ? '¿Quiénes somos?' : 'Who we are' }</span></AnchorLink>
                    </Button>
                </ListItem>
                <ListItem button>
                    <Button href="#" onClick=   {preventDefault}>
                        <AnchorLink offset='100' href='#sectionTwo' onClick={(e) => closeMenu(e)}><span>{ getLanguage ? 'Servicios' : 'Services' }</span></AnchorLink>
                    </Button>
                </ListItem>
                <ListItem button>
                    <Button href="#" onClick=   {preventDefault}>
                        <AnchorLink offset='100' href='#sectionThird' onClick={(e) => closeMenu(e)}><span>{ getLanguage ? 'Posibles usos' : 'Possible uses' }</span></AnchorLink>
                    </Button>
                </ListItem>
                <ListItem button>
                    <Button href="#" onClick={preventDefault}>
                        <AnchorLink offset='100' href='#sectionFour' onClick={(e) => closeMenu(e)}><span>{ getLanguage ? 'Realice su solicitud' : 'Make your request' }</span></AnchorLink>
                    </Button>
                </ListItem>
                <ListItem button>
                    <Button href="#" onClick={preventDefault} >
                        <AnchorLink offset='100' href='#sectionFive' onClick={(e) => closeMenu(e)}><span>{ getLanguage ? 'Preguntas y respuestas' : 'Q&A' }</span></AnchorLink>
                    </Button>
                </ListItem>
                <ListItem button>
                    <Button href={ getLanguage ? 'https://archivoaudiovisual.caracoltv.com/tarifas2024.xlsx' : 'https://archivoaudiovisual.caracoltv.com/tarifas2024.xlsx'} >
                        <a><span>{ getLanguage ? 'Tarifas' : 'Rate Card' }</span></a>
                    </Button>
                </ListItem>
                <ListItem button className="last-item">
                    <Button href="#" onClick={(e) => changueLanguage(e)}>
                    <span>{ getLanguage ? 'English version' : 'Versión en español' }</span>
                    </Button>
                </ListItem>
            </List>
        </Fragment>
    )
}

export default Listas;