import React, { Fragment } from 'react';
import { AppBar, makeStyles, Toolbar, Hidden, Link } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Box from './Box';
import BackVideo from './BackVideo';
import Lottie from 'react-lottie'
import menuOne from '../animations/menuOne/data.json'
import menuTwo from '../animations/menuTwo/data.json'

// imagenes
import LogoWhite from '../images/logo-mesa-de-trabajo-1-copia@3x.png';
import LogoBlue from '../images/logo-mesa-de-trabajo-1@3x.png';
import IconWhite from '../images/menu-movil@3x.png';
import IconBlue from '../images/menu-movil-blue@3x.png';
import IconWhiteLess from '../images/menu-less-white@3x.png';
import IconBlueLess from '../images/menu-less@3x.png';

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    root: {
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'row-reverse',
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `100%`,
            marginLeft: 0,
        }
    }
}));

const MenuMobile = ({ConstLanguage, setConstLanguage, profileState, hasscrolled}) => {

    const preventDefault = (event) => event.preventDefault();
    const classes = useStyles();
    const [abrir, setAbrir] = React.useState(false);
    const accionAbrir = () => {
        setAbrir(!abrir);
    }

    const menuOneOptions = {
        loop: false,
        autoplay: true,
        prerender: true,
        animationData: menuOne,
    }

    const menuTwoOptions = {
        loop: false,
        autoplay: true,
        prerender: true,
        animationData: menuTwo,
    }

    return (
        <Fragment>
            <AppBar position="fixed" color="transparent" className={classes.appBar}>
              <Toolbar>
              <Link href="#" onClick={preventDefault}  className="caracol-logo caracol-logo-mobile">
                    {hasscrolled ?
                        <img src={ LogoBlue } alt="LOGO CARACOL TELEVISION"></img>
                    :
                        <img src={ LogoWhite } alt="LOGO CARACOL TELEVISION"></img>
                    }
              </Link>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => accionAbrir()} >
                    { hasscrolled ?
                        abrir ?
                            <div className="menuBlue">
                                <Lottie options={ menuTwoOptions } className="menuTwo" height={28} width={23} />
                            </div>
                        :
                            <div className="menuBlue">
                                <Lottie options={ menuOneOptions } className="menuTwo" height={28} width={23} />
                            </div>
                    :
                        abrir ?
                            <div className="menuWhite">
                                <Lottie options={ menuTwoOptions } className="menuOne" height={28} width={23} />
                            </div>
                        :
                            <div className="menuWhite">
                                <Lottie options={ menuOneOptions } className="menuOne" height={28} width={23} />
                            </div>
                    }
                </IconButton>
              </Toolbar>
            </AppBar>
            <Hidden>
                <Box
                    variant="temporary"
                    open={abrir}
                    onClose={accionAbrir}
                    ConstLanguage={ConstLanguage}
                    setConstLanguage={setConstLanguage}
                    setAbrir={setAbrir}
                ></Box>
            </Hidden>
            <BackVideo ConstLanguage={ConstLanguage}></BackVideo>
            <div className={classes.offset}></div>
        </Fragment>
    )
}

export default MenuMobile;