
//Fonts

export const fonts = {
    one: 'montserratMedium, arial, sans-serif',
    two: 'montserratRegular, arial, sans-serif' ,
    three: 'montserratSemibold, arial, sans-serif',
    size7pt: "7px" /* "9px" */,
    size8pt: "8px" /* "11px" */,
    size10pt: "10px" /* "13px" */,
    size10ptNormal: "10px" /* "13px" */,
    size11pt: "11px" /* "15px" */,
    size12pt: "12px" /* "16px" */,
    size13pt: "13px" /* "17px" */,
    size14pt: "14px" /* "19px" */,
    size15pt: "15px" /* "21px" */,
    size16pt: "16px" /* "22px" */,
    size20pt: "20px" /* "26px" */,
    size23pt: "23px" /* "30px" */,
    size24pt: "24px" /* "24pt" */,
    size30pt: "30px" /* "24pt" */,
    size46pt: "46px" /* "24pt" */,
    weight500: "500",
    weight600: "600",
    weightnormal: "normal",
    weightbold: "bold",
    lineheightnormal: "normal",
    lineheightUnoOcho: "1.8",
    lineheightUnoCinco: "1.57",
    italic: "font-style: italic"
}

//Colors

export const colors = {
    white: 'rgb(255, 255, 255)' , black: '#000000' , gray: '#484848', blueDark: '#1e3577', blueMiddle: '#0f57ca', blueLigth: '#1ba9ff', textbluelight: '#00aaff', textbluedark: '#0073ff', grayTwo: '#cecccc'
}

//Parameters

export const mixins ={
    box : 'box-sizing: border-box'
}


//Gap

export const gap = {
    gap5: `5px`,
    gap10: `10px`,
    gap20: `20px`,
    gap30: `30px`,
    gapPoints: `13.2px`
}


// Flex

export const flex = {
    flexCross: `display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex;`,
    flexcolumns: `display: flex; flex-direction: column;`,
    flexrows: `display: flex; flex-direction: flex-rows;`,
    flexcentervertical: `align-items: center;`,
    flexbottomvertical: `align-items: flex-end; align-self: flex-end;`,
    flexjustifyhorizontal: `justify-content: center;`,
    flexjustifyhorizontalleft: `justify-content: flex-start;`
}


// Parameters

export const boxsizing = {
    box : 'box-sizing: border-box'
}


// Gradient

export const gradient = {
    whitetot : `/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+60&0+0,1+60 */
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    `,
    blacktot : `/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.80+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.80) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.80) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.80) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */`
}

// Titles

export const titles = {
    title : `font-family: ${fonts.three}; color: ${colors.black}; font-size: ${fonts.size24pt}; font-weight: ${fonts.weight600}; line-height: ${fonts.lineheightUnoOcho};`,

    menu : `font-family: ${fonts.one}; color: ${colors.gray}; font-size: ${fonts.size8pt}; font-weight: ${fonts.weight500}; line-height: ${fonts.lineheightnormal};`,

    video : `font-family: ${fonts.three}; color: ${colors.white}; font-size: ${fonts.size20pt}; font-weight: ${fonts.weight500}; line-height: ${fonts.lineheightnormal};`,

    tituloapertura : `font-family: ${fonts.three}; color: ${colors.gray}; font-size: ${fonts.size15pt}; font-weight: ${fonts.weight500}; line-height: 1.23;`,

    titulocategoria : `font-family: ${fonts.three}; color: ${colors.black}; font-size: ${fonts.size23pt}; font-weight: ${fonts.weight600}; line-height: 1.22;`,

    subtitulos : `font-family: ${fonts.three}; color: ${colors.black}; font-size: ${fonts.size16pt}; font-
    weight: ${fonts.weight600}; line-height: 1.22;`,

    bulletnumeros : `font-family: ${fonts.three}; color: ${colors.textbluedark}; font-size: ${fonts.size10pt}; font-weight: ${fonts.weight600}; line-height: 1.22;`,

    cuerpodetexto : `font-family: ${fonts.two}; color: ${colors.gray}; font-size: ${fonts.size14pt}; font-weight: ${fonts.normal}; line-height: ${fonts.lineheightUnoCinco};`,

    cuerpodetextoocho : `font-family: ${fonts.two}; color: ${colors.black}; font-size: ${fonts.size7pt}; font-weight: ${fonts.normal}; line-height: ${fonts.lineheightUnoCinco};`,

    linkdetexto : `font-family: ${fonts.two}; color: ${colors.textbluelight}; font-size: ${fonts.size9pt}; font-weight: ${fonts.weight600}; line-height: ${fonts.lineheightnormal};`,

    tituloactivo : `font-family: ${fonts.two}; color: ${colors.textbluedark}; font-size: ${fonts.size10pt}; font-weight: ${fonts.weight600}; line-height: 1.2;`,

    itemmenu : `font-family: ${fonts.one}; color: ${colors.white}; font-size: ${fonts.size8pt}; font-weight: ${fonts.weight500}; line-height: ${fonts.lineheightnormal};`,

    itemaccordion : `font-family: ${fonts.one}; color: ${colors.black}; font-size: ${fonts.size16pt}; font-weight: ${fonts.weight600}; line-height: ${fonts.lineheightUnoCinco};`,

    yeartext : `font-family: ${fonts.two}; color: ${colors.black}; font-size: ${fonts.size14pt}; font-weight: ${fonts.weight500}; line-height: ${fonts.lineheightnormal};`
}