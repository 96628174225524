import React, {Fragment} from 'react';
import { Grid } from '@material-ui/core';


const ContentThird = ({ ConstLanguage }) => {

    const getLanguage = ConstLanguage;

    return (
        <Fragment>
            <div className={ getLanguage ? "divContent animationSpanish divContent-paddingThirty" : "divContent animationEnglish divContent-paddingThirty"}>
                <Grid container spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={6} className="itemGrid">
                        <h2>{ getLanguage ? 'Posibles usos' : 'Possible uses' } </h2>

                        { getLanguage ?
                            <Fragment>
                                <p>El uso del material que usted requiera está sujeto al alcance de su solicitud y a la disponibilidad de los registros. De acuerdo a ello licenciamos el material del que somos titulares así:</p>
                            </Fragment>
                        :
                            <Fragment>
                                <p>The final use of the material depends of the scope of your request and the availability of the items. In that sense, we can only license the material we own the copyrights as follows:</p>
                            </Fragment>
                        }

                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} className="itemGrid">

                    </Grid>
                </Grid>
            </div>
            <div className={ getLanguage ? "divContent animationSpanish" : "divContent animationEnglish"}>
            <Grid container spacing={0} className="itemGrid itemGrid-PaddingDesktop">
                        <Grid item={true} xs={12} sm={12} md={6} lg={4} className="itemGrid">
                            <div className="itemsPossiblesUses">
                                { getLanguage ?
                                <Fragment>
                                    <h3><strong>1.</strong> Uso privado</h3>
                                    <p>Si usted es el sujeto principal de una nota periodística o informativa, o apareció como invitado de alguno de nuestros programas de entretenimiento.  </p>
                                </Fragment>
                                :
                                <Fragment>
                                    <h3><strong>1.</strong> Private Use</h3>
                                    <p>If you’re the principal subject of a news clip, or a guest in one of our entertainment programs. </p>
                                </Fragment>
                                }
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={6} lg={4} className="itemGrid">
                            <div className="itemsPossiblesUses">
                                { getLanguage ?
                                <Fragment>
                                    <h3><strong>2.</strong> Uso para consulta - investigación de orden académico</h3>
                                    <p>Si usted está realizando alguna investigación con fines académicos y quiere consultar el material única y exclusivamente como contribución a los resultados de su investigación. </p>
                                </Fragment>
                                :
                                <Fragment>
                                    <h3><strong>2.</strong>  Academic Research Use</h3>
                                    <p>If you’re doing an academic research and wish to visualize the material only with research purposes. </p>
                                </Fragment>
                                }
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={6} lg={4} className="itemGrid">
                            <div className="itemsPossiblesUses">
                                { getLanguage ?
                                <Fragment>
                                    <h3><strong>3.</strong>Uso por parte de autoridades judiciales o de policía y entes de control o solicitud de material probatorio para procesos judiciales</h3>
                                    <p>Por favor contacte a nuestra oficina jurídica <a href="mailto:oficinajuridica2@caracoltv.com.co">oficinajuridica2@caracoltv.com.co</a></p>
                                </Fragment>
                                :
                                <Fragment>
                                    <h3><strong>3.</strong>Commercial or Public Use-News and Entertainment Material</h3>
                                    <p>If you’re developing an audiovisual project for commercial or public purposes. We license according the nature of the content and the scope of media and territories.  </p>
                                </Fragment>
                                }
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={6} lg={4} className="itemGrid">
                            <div className="itemsPossiblesUses">
                            { getLanguage ?
                                <Fragment>
                                    <h3><strong>4.</strong>Uso comercial o público - noticias y entretenimiento</h3>
                                    <p>Si usted está desarrollando un proyecto audiovisual que tendrá difusión comercial y/o pública. Establecemos los términos de licenciamiento de acuerdo a la naturaleza del contenido y alcance de medios y territorios.</p>
                                </Fragment>
                                :
                                <Fragment>
                                    <h3><strong>4.</strong>Commercial or Public Use- Sports</h3>
                                    <p>If you’re developing an audiovisual project for commercial or public purposes. We license according the nature of the content and the scope of media and territories.</p>
                                </Fragment>
                                }
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={6} lg={4} className="itemGrid">
                            <div className="itemsPossiblesUses">
                            { getLanguage ?
                                <Fragment>
                                    <h3><strong>5.</strong>Uso comercial o público - deportes</h3>
                                    <p>Si usted está desarrollando un proyecto audiovisual que tendrá difusión comercial y/o pública. Establecemos los términos de licenciamiento de acuerdo a la naturaleza del contenido y alcance de medios y territorios.</p>
                                </Fragment>
                                :
                                <Fragment>
                                </Fragment>
                                }
                            </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={6} lg={4} className="itemGrid">
                            <div className="itemsPossiblesUses">
                            { getLanguage ?
                                <Fragment>
                                    <h3><strong>6.</strong>Uso para “reels”</h3>
                                    <p>Si usted hace o hizo parte de nuestro equipo de trabajo como actor o presentador.</p>
                                </Fragment>
                                :
                                <Fragment>
                                </Fragment>
                                }
                            </div>
                        </Grid>
                    </Grid>
            </div>
        </Fragment>
    )
}

export default ContentThird;