import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { Hidden } from '@material-ui/core/';

// imagenes
import back from '../images/back@3x.png';
import next from '../images/next@3x.png';

import logoone from '../images/visualizacion-logo-caracol-tv-1969-1979@3x.png';
import logotwo from '../images/visualizacion-logo-caracol-tv-1979-1981@3x.png';
import logotree from '../images/visualizacion-logo-caracol-tv-1981-1983@3x.png';
import logofour from '../images/visualizacion-logo-caracol-tv-1983-1987@3x.png';
import logofive from '../images/visualizacion-logo-caracol-tv-1987-2000-01@3x.png';
import logosix from '../images/visualizacion-logo-caracol-tv-2000-2003@3x.png';
import logoseven from '../images/visualizacion-logo-caracol-tv-2003-2012@3x.png';
import logoeight from '../images/visualizacion-logo-caracol-television-2020-01@3x.png';

export default () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  return (
    <div style={{ padding: `0 ${chevronWidth}px` }}>
      <Hidden smDown>
        <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={5}
        gutter={10}
        leftChevron={<img src={ back } alt="back" />}
        rightChevron={<img src={ next } alt="next" />}
        outsideChevron
        chevronWidth={chevronWidth}
      >
        <div className="contentImage"><img src={ logoone } alt="1969-1979" /><span>1969-1979</span></div>
        <div className="contentImage"><img src={ logotwo } alt="1979-1981" /><span>1979-1981</span></div>
        <div className="contentImage"><img src={ logotree } alt="1981-1983" /><span>1981-1983</span></div>
        <div className="contentImage"><img src={ logofour } alt="1983-1987" /><span>1983-1987</span></div>
        <div className="contentImage"><img src={ logofive } alt="1987-2000" /><span>1987-2000</span></div>
        <div className="contentImage"><img src={ logosix } alt="2000-2003" /><span>2000-2003</span></div>
        <div className="contentImage"><img src={ logoseven } alt="2003-2012" /><span>2003-2012</span></div>
        <div className="contentImage"><img src={ logoeight } alt="2003-2012" /><span>2012</span></div>
        </ItemsCarousel>
      </Hidden>
      <Hidden mdUp>
        <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={2}
        gutter={10}
        leftChevron={<img src={ back } />}
        rightChevron={<img src={ next } />}
        outsideChevron
        chevronWidth={chevronWidth}
      >
        <div className="contentImage"><img src={ logoone } alt="1969-1979" /><span>1969-1979</span></div>
        <div className="contentImage"><img src={ logotwo } alt="1979-1981" /><span>1979-1981</span></div>
        <div className="contentImage"><img src={ logotree } alt="1981-1983" /><span>1981-1983</span></div>
        <div className="contentImage"><img src={ logofour } alt="1983-1987" /><span>1983-1987</span></div>
        <div className="contentImage"><img src={ logofive } alt="1987-2000" /><span>1987-2000</span></div>
        <div className="contentImage"><img src={ logosix } alt="2000-2003" /><span>2000-2003</span></div>
        <div className="contentImage"><img src={ logoseven } alt="2003-2012" /><span>2003-2012</span></div>
        <div className="contentImage"><img src={ logoeight } alt="2003-2012" /><span>2012</span></div>
        </ItemsCarousel>
        </Hidden>
    </div>
  );
};