import React, { Fragment } from 'react';

const Footer = () => {

    return (
        <Fragment>
            <div className="block footer">
                footer
            </div>
        </Fragment>
    )
}

export default Footer;