import styled, { keyframes } from 'styled-components';

export const animationSky = keyframes`
    0% {  background-position: center 0px }
    50% { background-position: center -50px }
    100% {  background-position: center 0px }
`;

export const animationPlane = keyframes`
    0% {  transform: rotate(0) }
    50% { transform: rotate(-10deg) }
    100% {  transform: rotate(0) }
`;