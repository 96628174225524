import React, {Fragment} from 'react';
import { Grid, Divider, Button } from '@material-ui/core';

// Acordeon
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// imagenes
import request from '../images/group-327@3x.png';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

const ContentFive = ({ ConstLanguage }) => {

    const getLanguage = ConstLanguage;

    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Fragment>
            <div className={ getLanguage ? "divContent animationSpanish" : "divContent animationEnglish"}>
                <div className="divAcordeon">
                    <Grid container spacing={0}>
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} className="itemGrid">
                            <h2>{ getLanguage ? 'Preguntas y respuestas' : 'Q&A' } </h2>

                            <div className={classes.root}>

                                { getLanguage ?
                                    <Fragment>

                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>
                                            ¿Qué tipo de registros tiene el archivo?
                                        </Typography>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Imágenes producidas para televisión que han hecho parte de la producción de noticias y de programas de entretenimiento realizados por Caracol Televisión, tanto en su fase como programadora; como desde 1998, cuando empezó a funcionar como canal privado.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿Cuál es el valor comercial de las imágenes?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Depende del uso y licencia solicitada. Por favor consulte nuestra <a href="https://archivoaudiovisual.caracoltv.com/tarifas2020_ES.xlsx">tabla de tarifas</a>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿Puedo utilizar las imágenes del archivo para uso privado o personal?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Podemos licenciar las imágenes para uso privado si usted es una persona natural que aparece dentro de alguno de nuestros programas, por el tiempo que dure la nota o informe. El uso personal y de consulta está restringido exclusivamente para investigaciones académicas o para uso en “reels” por parte de talento que hace o ha hecho parte del Canal a lo largo de su historia.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿Puedo utilizar las imágenes con propósitos comerciales?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Sí. De acuerdo al uso que vaya a dar al material emitimos una licencia que le da la posibilidad de explotación en los medios y territorios que de alcance a su proyecto.
                                                    <br /><br />
                                                    Las condiciones de licenciamiento están sujetas a las características específicas del contenido solicitado.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel5bh-content"
                                            id="panel5bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿Puedo acceder a la base de datos para visualizar el contenido del archivo?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    No, nuestra base de datos no es pública. Son nuestros documentalistas quienes se encargan de dar curso a las solicitudes ingresadas en el formulario.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel6bh-content"
                                            id="panel6bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿La digitalización y copiado del material que solicito tiene valor?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Sí, nuestras tarifas incluyen, además del licenciamiento, los procesos asociados al alistamiento del material para nuestros clientes.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel7bh-content"
                                            id="panel7bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    Tengo un proyecto que requiere utilizar material de archivo y debo visualizar antes de seleccionar, ¿Cuánto cuesta y cuánto tiempo toma?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Depende del alcance del proyecto. Si su solicitud implica un largo proceso de selección cobramos una tarifa anticipada de investigación y curaduría por 60 USD semanales. De acuerdo a la especificidad de su solicitud, podemos entregar un reporte del material disponible en 5 días hábiles, pero si su solicitud es muy amplia o genérica, probablemente tome más tiempo.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel8bh-content"
                                            id="panel8bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿Cuanto tiempo tardan en responder mi solicitud?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    El tiempo de respuesta varía de 1 a 5 días calendario, de acuerdo a la extensión y viabilidad de la solicitud.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel9bh-content"
                                            id="panel9bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    ¿Cómo recibo el material?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Hacemos una transferencia digital en baja resolución con time code y marca de agua para su visualización y selección, posterior a ello entregamos el material con las condiciones de resolución que se soliciten una vez se termine el proceso de facturación y licenciamiento.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                    </Fragment>
                                :
                                    <Fragment>

                                        <Accordion expanded={expanded === 'panel1e'} onChange={handleChange('panel1e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    What kind of material can I see in this archive?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Images produced for broadcast television, as part of news and entertainment programs produced by Caracol Televisión since the 1970s.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel2e'} onChange={handleChange('panel2e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    What is the commercial value of the images?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    It depends on the use and license required. Please see our <a href="https://archivoaudiovisual.caracoltv.com/tarifas2020_EN.xlsx">rate card</a>
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel3e'} onChange={handleChange('panel3e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    Can I request material for private or personal use?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    We can license images for private use if you’re an individual who was interviewed or a guest in one of our programs; exclusively for academic research or for your use in your reel or portfolio, if you work / used to work for our network.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel4e'} onChange={handleChange('panel4e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    Can I use the images for commercial purposes?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Yes, depending of the use you’ll make of the material we can issue a license that gives you the possibility of commercial use in the media and territories your project requires.
                                                    <br /><br />
                                                    The licensing conditions depend on the specificity of the content you request.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel5e'} onChange={handleChange('panel5e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel5bh-content"
                                            id="panel5bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    Can I access your database or catalogue to visualize the metadata or content of the archive?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    No, we don’t have a public catalogue. Our cataloguers are in charge of researching and curating the material you request.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel6e'} onChange={handleChange('panel6e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel6bh-content"
                                            id="panel5bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    Is there a digitization or copy fee?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Yes, our rates include, besides licensing, the processes associated with digitizing and transcoding the material for our clients.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel7e'} onChange={handleChange('panel7e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel7bh-content"
                                            id="panel7bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    I have a project that requires use of archival footage and I have to visualize it before making a final decision. Does it cost and how long does it take?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    It depends on the scope of the project. If your request demands a thorough research and curation process, we charge in advance a research and curation fee of 60 USD per week. According to the specificity of your request, we can deliver a previous report of the available material in 5 work days, but if your request is too wide or generic, it will probably take more time.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                        <Accordion expanded={expanded === 'panel8e'} onChange={handleChange('panel8e')}>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel8bh-content"
                                            id="panel8bh-header"
                                            >
                                                <Typography className={classes.heading}>
                                                    How do I receive the material I requested?
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    We make a digital transfer of the images in low resolution, with time code and logo, for you to visualize and make your selection. After you make your final selection, we send you the material with the resolution and format you need, as long as the billing and the licensing process is complete.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Divider className="dividerShort"></Divider>
                                    </Fragment>
                                }

                            </div>

                        </Grid>
                    </Grid>
                </div>
            </div>
         </Fragment>
    )
}

export default ContentFive;