import React, {Fragment} from 'react';
import { Grid } from '@material-ui/core';
import CarouselLogos from '../components/Carousel.js';

// imagenes
import imageOne from '../images/quienes-somos.png';

const ContentOne = ({ ConstLanguage }) => {

    const getLanguage = ConstLanguage;

    return (
        <Fragment>
            <div className={ getLanguage ? "divContent animationSpanish firstSection" : "divContent animationEnglish"}>
                <Grid container spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={7} className="itemGrid">
                        <h2>{ getLanguage ? 'Quienes somos' : 'Who we are' } </h2>

                        { getLanguage ?
                            <Fragment>
                                <p>Somos un archivo audiovisual que alberga miles de imágenes vinculadas a la historia y actualidad del país, producidas para los programas de noticias y entretenimiento de Caracol Televisión desde la década de 1970.</p>
                                <p>Nuestra colección alberga los registros audiovisuales de los hechos y personajes que son y han sido noticia en Colombia desde hace más de cuatro décadas.</p>
                                <p>Además, poseemos un extenso banco de imágenes, grabadas por nuestros reporteros y periodistas a lo largo y ancho del territorio nacional.</p>
                            </Fragment>
                        :
                            <Fragment>
                                <p>We are an audiovisual archive that holds thousands of moving images linked to the history and latest events taking place in Colombia, produced for the news and entertainment programs of Caracol Televisión since the 1970s. </p>
                            </Fragment>
                        }

                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={5} className="itemGrid">

                        <div className="containerImage">
                            <img src={ imageOne } />
                        </div>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} className="itemGrid">
                        <div className="carouseLogos">
                            <CarouselLogos />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

export default ContentOne;