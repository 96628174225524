import React, {useState, useEffect, Fragment} from 'react';

// importado los otros componentes
import HeaderContent from './components/Header';
import ContentOne from './components/ContentOne';
import ContentTwo from './components/ContentTwo';
import ContentThird from './components/ContentThird';
import ContentFour from './components/ContentFour';
import Footer from './components/Footer';
import ContentFive from './components/ContentFive';


//Styles
import { GlobalStyle } from './styles/GridStyles';


function App() {

  // Idioma
  const [ ConstLanguage, setConstLanguage ] = useState(true);

  const[hasscrolled, setHasScrolled] = useState(false);

  useEffect(() => {
      window.addEventListener('scroll',handleScroll);
  }, [])

  const handleScroll = () => {
      const scrolltop = window.pageYOffset
          if(scrolltop  > 150){
              setHasScrolled(true);
          }else{
              setHasScrolled(false);
          }
  }

  return (
      <Fragment>
        <GlobalStyle></GlobalStyle>
        <div className={hasscrolled ? 'Header HeaderScrolled' : 'Header'}><HeaderContent ConstLanguage={ConstLanguage} setConstLanguage={setConstLanguage} hasscrolled={hasscrolled}></HeaderContent></div>
        <section id='sectionOne'>
            <ContentOne ConstLanguage={ConstLanguage}></ContentOne>
        </section>
        <section id='sectionTwo'>
            <ContentTwo ConstLanguage={ConstLanguage}></ContentTwo>
        </section>
        <section id='sectionThird'>
            <ContentThird ConstLanguage={ConstLanguage}></ContentThird>
        </section>
        <section id='sectionFour'>
            <ContentFour ConstLanguage={ConstLanguage}></ContentFour>
        </section>
        <section id='sectionFive'>
            <ContentFive ConstLanguage={ConstLanguage}></ContentFive>
        </section>
        <Footer></Footer>
      </Fragment>
  );
}

export default App;
