import React from 'react';

import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core/';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../temaConfig';

// componentes
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';

const HeaderContent = ({ConstLanguage, setConstLanguage, hasscrolled}) => {
    return (
        <ThemeProvider theme={theme}>
            <Hidden smDown>
                <MenuDesktop ConstLanguage={ConstLanguage}  setConstLanguage={setConstLanguage} hasscrolled={hasscrolled} />
            </Hidden>
            <Hidden mdUp>
                <MenuMobile ConstLanguage={ConstLanguage}  setConstLanguage={setConstLanguage} hasscrolled={hasscrolled} />
            </Hidden>
        </ThemeProvider>
    );
}

export default HeaderContent;