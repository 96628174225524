import React, { Fragment,useState } from 'react';
import { Grid, Divider, Button, Link, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Modal from "react-modal";

// imagenes
import request from '../images/group-327@3x.png';

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    menuButton: {
        marginRight: 0,
        justifyContent: 'flex-end',
        color: '#000000',
        float: 'right',
        cursor: 'pointer'
    }
}));

const ContentFour = ({ ConstLanguage }) => {

    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const getLanguage = ConstLanguage;

    return (
        <Fragment>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Formulario"
                className="mymodal"
            >
                <a onClick={toggleModal} >
                    <CloseIcon
                        edge="start"
                        className={classes.menuButton}
                        aria-label="close"
                    >
                    </CloseIcon>
                </a>
                { getLanguage ?
                <iframe src="https://appsgti.nemqueteba.com:81/Solicitudes.aspx?Lang=1" width="100%" height="100%" id="inlineFrameExample" title="Inline Frame Example" frameborder="0"></iframe>
                :
                <iframe src="https://appsgti.nemqueteba.com:81/Solicitudes.aspx?Lang=2" width="100%" height="100%" id="inlineFrameExample" title="Inline Frame Example" frameborder="0"></iframe>
                }
            </Modal>
            <div className={ getLanguage ? "divContent animationSpanish" : "divContent animationEnglish"}>
                <Grid container spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={7} className="itemGrid">
                        <h2>{ getLanguage ? 'Realice su solicitud' : 'Make your request' } </h2>

                        { getLanguage ?
                            <Fragment>
                                <p>A través de este formulario usted podrá realizar solicitudes de material producido por Caracol Televisión para los noticieros y programas informativos, documentales y programas de entretenimiento.</p>

                                <p>Antes de hacerlo le recomendamos visitar nuestra sección de preguntas y respuestas
                                En este formulario usted NO podrá solicitar la compra o derechos de uso de programas completos. Si está interesado en adquirir programas, series u otras obras completas del canal por favor comuníquese con el área de ventas internacionales: <a href="mailto:ventaint@caracoltv.com.co">ventaint@caracoltv.com.co</a></p>

                                <Divider className="dividerShort"></Divider>
                                <p>Si es cliente (uso comercial o privado) ingrese al formulario aquí<Button onClick={toggleModal} target="_blank">Solicitar</Button></p>

                                <Divider className="dividerShort"></Divider>

                                <p>Si es autoridad judicial, de policía o ente de control : Por favor contacte a nuestra oficina jurídica: <a href="mailto:oficinajuridica2@caracoltv.com.co">oficinajuridica2@caracoltv.com.co</a>
</p>
                            </Fragment>
                        :
                            <Fragment>
                                <p>Through this form, you can make request of material produced by Caracol Televisión for the news broadcast, documentaries and entertainment programs.</p>

                                <p>Before you start we advise you to visit our Q&A section.</p>

                                <p>With this form you can’t request the purchase or licensing of full programs or series. If you’re interested to acquire complete works please contact our International Sales team:  <a href="mailto:ventaint@caracoltv.com.co">ventaint@caracoltv.com.co</a></p>

                                <Divider className="dividerShort"></Divider>
                                <p>You can access the form here <Button onClick={toggleModal} target="_blank">Make a request</Button></p>
                                <Divider className="dividerShort"></Divider>
                            </Fragment>
                        }

                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={6} lg={5} className="itemGrid itemGrid-image">
                    <img src={ request } alt="Digitalizamos y/o transcodificamos" />
                    </Grid>
                </Grid>
            </div>
         </Fragment>
    )
}

export default ContentFour;