import React, {Fragment} from 'react';
import { Grid } from '@material-ui/core';

// imagenes
import cyl from '../images/group-147@3x.png';
import dyt from '../images/group-328@3x.png';
import iyc from '../images/group-577@3x.png';

const ContentTwo = ({ ConstLanguage }) => {

    const getLanguage = ConstLanguage;

    return (
        <Fragment>
            <div className={ getLanguage ? "divContent animationSpanish" : "divContent animationEnglish"}>
                <Grid container spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={3} lg={3} className="itemGrid centerAll">
                        <h2>{ getLanguage ? 'Servicios' : 'Services' }</h2>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={3} lg={3} className="itemGrid">
                        <div className="contentImageTitle">
                            <img src={ cyl } alt="Comercializamos y licenciamos" />
                            <h3>
                            { getLanguage ? 'Comercializamos y licenciamos' : 'We sell and license' }
                            </h3>
                        </div>
                        <div className="contentServicesText">
                            <p>
                            { getLanguage ? 'Material asociado a nuestras producciones noticiosas e informativas y programas de entretenimiento.' : 'Material produced for our news and entertainment programs.' }
                            </p>
                        </div>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={3} lg={3} className="itemGrid">
                        <div className="contentImageTitle">
                            <img src={ dyt } alt="Digitalizamos y/o transcodificamos" />
                            <h3>
                            { getLanguage ? 'Digitalizamos y/o transcodificamos' : 'We digitize and transcode' }
                            </h3>
                        </div>
                        <div className="contentServicesText">
                            <p>
                            { getLanguage ? 'El material de soportes analógicos o formatos de reproducción obsoletos, únicamente de los registros que hacen parte de nuestro archivo y que sean objeto de alguna solicitud. ' : 'The material from analogic carriers of obsolete formats, only with the items that are part of our archive.' }
                            </p>
                        </div>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={3} lg={3} className="itemGrid">
                        <div className="contentImageTitle">
                            <img src={ iyc } alt="Investigamos y curamos" />
                            <h3>
                                { getLanguage ? 'Investigamos y curamos' : 'We research and curate' }
                            </h3>
                        </div>
                        <div className="contentServicesText">
                            <p>
                            { getLanguage ? 'los registros que hacen parte de las solicitudes de nuestros clientes.' : 'The material to answer our client’s requests.' }
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    )
}

export default ContentTwo;