import { Hidden } from '@material-ui/core';
import React, { Fragment, useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ReactBodymovin from 'react-bodymovin'
import mouseOne from '../animations/mouseOne/data.json'
import mouseTwo from '../animations/mouseTwo/data.json'

const BackVideo = ({ConstLanguage, hasscrolled}) => {

    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll',handleScrollButton);
    }, [])

    const handleScrollButton = () => {

        const scrolltop = window.pageYOffset;

        const scrollbottom = window.innerHeight;

        if(scrolltop => scrollbottom){
            setIsShown(false);
        }

    }

    const mouseOneOptions = {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: mouseOne
    }

    const mouseTwoOptions = {
        loop: true,
        autoplay: true,
        prerender: true,
        animationData: mouseTwo
    }

    const mouseShown = (e) => {
        if(isShown == false) {
            setIsShown(true);
        } else {
            setIsShown(false);
        }
    }

    const getLanguage = ConstLanguage;

    return (
        <Fragment>
            <div className="Video">
                <AnchorLink offset='100' href='#sectionOne' className="btnMouse" onMouseEnter={(e) => mouseShown(e)} onMouseLeave={(e) => mouseShown(e)} onClick={(e) => setIsShown(false)}>
                    <div className={ isShown ? 'visible' : 'invisible'}>
                        <ReactBodymovin options={ mouseOneOptions } className="mouseTwo"/>
                    </div>
                    <div className={ isShown ? 'invisible' : 'visible'}>
                        <ReactBodymovin options={ mouseTwoOptions } className="mouseOne"/>
                    </div>
                </AnchorLink>
                <Hidden xsDown>
                    <video autoPlay muted loop >
                        <source src="https://archivoaudiovisual.caracoltv.com/video-desktop.mp4" type="video/mp4" />
                    </video>
                </Hidden>
                <Hidden smUp>
                    <video autoPlay muted loop >
                        <source src="https://archivoaudiovisual.caracoltv.com/video-mobile.mp4" type="video/mp4" />
                    </video>
                </Hidden>
                { getLanguage ?
                    <h1>La actualidad, el acontecer político y la historia contemporánea de Colombia están en el archivo audiovisual de Caracol Televisión</h1>
                    :
                    <h1>Current events, political landscape and contemporary history of Colombia through moving images are in Caracol Television’s Audiovisual Archive</h1>
                }
            </div>
        </Fragment>
    )
}

export default BackVideo;