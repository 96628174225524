import React from 'react';
import { makeStyles, Drawer, Divider } from '@material-ui/core';
import List from './List';

const estilos = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
        top: 56,
        [theme.breakpoints.up('md')]: {
            top: 68,
        },
    }
}))

const Box = ({ConstLanguage, setConstLanguage, open, setAbrir}) => {

    const classes = estilos()

    return (
        <Drawer
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="bottom"
            variant={open.variant}
            open={open}
            onClose={open.onClose ? open.onClose : null}
        >
            <List ConstLanguage={ConstLanguage} setConstLanguage={setConstLanguage} setAbrir={setAbrir}></List>
        </Drawer>
    )
}

export default Box;